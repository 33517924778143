import { Alert, Card, Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ITicket from "../../../models/helpdesk/it/ITicket";
import { useEffect, useState } from "react";
import Status from "../../../enums/Status";
import itHelpdeskService from "../../../services/itHelpdeskService";
import { Icon } from "../../../components/icon/Icon";
import dayjs from "dayjs";
import TicketBody from "./TicketBody";
import BackButton from "../../../components/backButton/BackButton";

const AlertBody = ({ variant, message }: { variant: string, message: string }) => (
    <Card.Body>
        <Card.Text as="div">
            <div className="d-flex align-items-center">
                <BackButton path="/it" />
                <Alert variant={variant} className="w-100 mb-0">{message}</Alert>
            </div>
        </Card.Text>
    </Card.Body>
);

export function Ticket() {
    const { ticketId } = useParams();
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [ticket, setTicket] = useState<ITicket>(null!);

    const id = Number(ticketId);

    useEffect(() => fetchTicket(id, setTicket, setStatus), [id]);

    const createdFormatted = ticket?.created
        ? dayjs(ticket!.created).format("DD/MM/YYYY HH:mm")
        : null;
    
    return (
        <Container className="py-3">
            <Row className="justify-content-center">
                <Col>
                    <Card className="shadow">
                        {
                            (status === Status.LOADING && <AlertBody variant="info" message={t("ticket.loading")} />) ||
                            (status === Status.LOAD_ERROR && <AlertBody variant="danger" message={t("ticket.errors.general")} />) ||
                            (status === Status.LOAD_SUCCESS && <TicketBody ticket={ticket} />)
                        }
                        {
                            status === Status.LOAD_SUCCESS && createdFormatted &&
                            <Card.Footer>
                                <div className="d-flex justify-content-between fs-sm">
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("general.createdBy")}</Tooltip>}>
                                        <span>
                                            <Icon iconName="PersonCircle" endMargin={1} />
                                            <a  href={`mailto:${ticket.contact!.email}?subject=[${ticket.ticketNumber}]`}
                                                className="mb-0 text-black text-decoration-none">
                                                {ticket.contact!.name}
                                            </a>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("general.created")}</Tooltip>}>
                                        <span>
                                            <Icon iconName="Calendar" endMargin={1} />
                                            <span className="mb-0">{createdFormatted}</span>
                                        </span>
                                    </OverlayTrigger>
                                </div>
                            </Card.Footer>
                        }
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

function fetchTicket(
    ticketId: number,
    setTicket: (ticket: ITicket) => void,
    setStatus: (status: Status) => void
) {
    itHelpdeskService.getTicket(ticketId)
        .then(ticket => {
            setTicket(ticket);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}