import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import FloatingTextField from "../../components/formFields/floatingText/FloatingTextField";
import { useEffect, useState } from "react";
import IMessage from "../../models/helpdesk/comms/IMessage";
import Status from "../../enums/Status";
import FormFieldValidation from "../../enums/FormFieldValidation";
import RichTextField from "../../components/formFields/richText/RichTextField";
import communicationService from "../../services/communicationsService";
import { NIL as NIL_UUID } from "uuid";
import INewMessageProps from "./INewMessageProps";

interface IMessageValidation {
    subject: FormFieldValidation;
    body: FormFieldValidation;
}

const newMessage = (ticketId: number): IMessage => ({
    id: NIL_UUID,
    ticketId: ticketId,
    sent: new Date(),
    subject: "",
    body: "",
    sender: {
        name: "",
        email: ""
    },
    recipients: []
});

export default function NewMessage(props: INewMessageProps) {
    const { t } = useTranslation();

    const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        const isValid = validate(message, setValidation);

        if (!isValid) {
            return;
        }

        setStatus(Status.SAVING);

        communicationService.createMessage(message)
            .then((result: boolean) => {
                if (!result) {
                    setStatus(Status.SAVE_ERROR);
                    return;
                }
                
                setStatus(Status.SAVE_SUCCESS);
                props.onMessageSent();
                setMessage(newMessage(props.ticketId));
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    };

    const [status, setStatus] = useState<Status>(Status.NEUTRAL);
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const [message, setMessage] = useState<IMessage>(newMessage(props.ticketId));

    const [validation, setValidation] = useState<IMessageValidation>({
        subject: FormFieldValidation.NOT_VALIDATED,
        body: FormFieldValidation.NOT_VALIDATED
    });

    useEffect(() => setIsReadOnly([Status.SAVE_SUCCESS, Status.SAVING, Status.LOADING, Status.LOAD_ERROR].includes(status)), [status]);

    const hasValidationErrors = Object.values(validation).some(v => v === FormFieldValidation.INVALID);

    const handleInputChange = (prop: keyof IMessage, value: any) => {
        setMessage(message => ({ ...message, [prop]: value }));
        setValidation(validation => ({ ...validation, [prop]: FormFieldValidation.NOT_VALIDATED }));
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h4>{t("comms.new")}</h4>
            <Row>
                <Col>
                    <FloatingTextField
                        label={t("comms.form.fields.title.label")}
                        type="text"
                        placeholder={t("comms.form.fields.title.placeholder")}
                        value={message.subject}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("subject", value)}
                        validation={validation.subject}
                        validationMessage={t("comms.form.fields.title.validation")}
                        />
                </Col>
            </Row>
            <Row>
                <Col>
                    <RichTextField
                        value={message.body}
                        placeholder={t("comms.form.fields.content.label")}
                        disabled={isReadOnly}
                        onChange={value => handleInputChange("body", value)}
                        validation={validation.body}
                        validationMessage={t("comms.form.fields.content.validation")}
                        />
                </Col>
            </Row>

            <Row>
                <Col>
                    {
                        hasValidationErrors &&
                        <Alert variant="danger">{t("comms.errors.newMessageValidation")}</Alert>
                    }
                    {
                        status === Status.SAVING &&
                        <Alert variant="info">{t("comms.form.sending")}</Alert>
                    }
                    {
                        status === Status.SAVE_SUCCESS &&
                        <Alert variant="success">{t("comms.form.sent")}</Alert>
                    }
                    {
                        status === Status.SAVE_ERROR &&
                        <Alert variant="danger">{t("comms.errors.sendingMessage")}</Alert>
                    }
                </Col>
            </Row>
            
            <Row className="justify-content-center">
                <Col xs={12} md={10} lg={6} xl={4}>
                    <div className="d-grid">
                        <Button type="submit" size="lg" variant="primary" disabled={isReadOnly}>{t("comms.form.send")}</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

function validate(
    message: IMessage,
    setValidation: (validation: IMessageValidation) => void
) {
    const validation: IMessageValidation = {
        subject: message.subject.length > 0 
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID,
        body: message.body.length > 0
            ? FormFieldValidation.VALID
            : FormFieldValidation.INVALID
    };

    setValidation(validation);

    return Object.values(validation).every(v => v === FormFieldValidation.VALID);
}