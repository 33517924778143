import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Status from "../../enums/Status";
import { Alert, ListGroup } from "react-bootstrap";
import NewMessage from "../newMessage/NewMessage";
import Message from "../message/Message";
import IMessage from "../../models/helpdesk/comms/IMessage";
import communicationService from "../../services/communicationsService";
import IMessagesProps  from "./IMessagesProps";

export default function Messages(props: IMessagesProps) {
    const { t } = useTranslation();
    
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [messages, setMessages] = useState<IMessage[]>([]);

    const loadMessages = useCallback(() => {
        setStatus(Status.LOADING);

        fetchMessages(props.ticketId)
            .then(comms => {
                setMessages(comms);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, [props.ticketId]);

    useEffect(() => loadMessages(), [loadMessages]);

    if (status === Status.LOADING) {
        return <Alert variant="info">{t("general.loading")}</Alert>;
    }

    if (status === Status.LOAD_ERROR) {
        return <Alert variant="danger">{t("comms.errors.general")}</Alert>;
    }

    if (messages.length === 0) {
        return (
            <>
                <Alert variant="info">{t("comms.errors.noMessages")}</Alert>
                <NewMessage ticketId={props.ticketId} onMessageSent={loadMessages} />
            </>
        );
    }

    return (
        <>
            {
                props.showMessages &&
                <ListGroup className="mb-3">
                    {messages.map(message => <Message message={message} />)}
                </ListGroup>
            }
            <NewMessage ticketId={props.ticketId} onMessageSent={props.onMessageSent ?? loadMessages} />
        </>
    );
}

async function fetchMessages(ticketId: number) {
    const messages = await communicationService.getMessages(ticketId);
    return messages;
}