import { useEffect, useState } from "react";
import Messages from "../messages/Messages";
import { ITicket } from "../../models/helpdesk/ITicket";
import Status from "../../enums/Status";
import { Button, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import itHelpdeskService from "../../services/itHelpdeskService";
import { Icon } from "../../components/icon/Icon";
import IQuickCommsProps from "./IQuickCommsProps";
import helpdeskService from "../../services/helpdeskService";
import hrHelpdeskService from "../../services/hrHelpdeskService";

const Success = ({ ticketNumber } : { ticketNumber: string }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column vh-100">
            <div className="d-flex align-items-center flex-grow-1 mb-1">
                    <div className="d-block text-center w-100">
                        <h2 className="text-success">
                            <Icon iconName="CheckCircle" />
                        </h2>
                        <h3>
                            {t("comms.form.sent")}
                        </h3>
                        <h5>
                            {ticketNumber}
                        </h5>
                    </div>
            </div>
        </div>
    );
}

const Error = ({ ticketId } : { ticketId: number }) => {
    const { t } = useTranslation();

    return (
        <div className="d-flex flex-column vh-100">
            <div className="d-flex align-items-center flex-grow-1 mb-1">
                    <div className="d-block text-center w-100">
                        <h2 className="text-danger">
                            <Icon iconName="XCircleFill" />
                        </h2>
                        <h3>
                            {t("general.error")}
                        </h3>
                        <h5>
                            {t("ticket.caseNumber", { number: ticketId })}
                        </h5>
                    </div>
            </div>
        </div>
    );
}

export default function QuickComms(props: IQuickCommsProps) {
    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [ticket, setTicket] = useState<ITicket>(null!);
    const [showMessages, setShowMessages] = useState(false);

    useEffect(() => fetchTicket(props.ticketId, setTicket, setStatus), [props.ticketId]);

    if (status === Status.LOADING) {
        return (
            <div className="text-center py-4">
                <Spinner />
            </div>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return <Error ticketId={props.ticketId} />;
    }

    if (status === Status.SAVE_SUCCESS) {
        return <Success ticketNumber={ticket.ticketNumber!} />;
    }

    return (
        <div className="px-2 py-1">
            <div className="d-flex align-items-center mb-1">
                <h2>{ticket.ticketNumber}</h2>
                <div className="ms-auto">
                    <Button onClick={() => setShowMessages(!showMessages)}>
                        {
                            showMessages
                                ? <Icon iconName="ChevronUp" />
                                : <Icon iconName="ChevronDown" />
                        }
                    </Button>
                </div>
            </div>
            <Messages ticketId={props.ticketId} showMessages={showMessages} onMessageSent={() => setStatus(Status.SAVE_SUCCESS)} />
        </div>
    );
}

function fetchTicket(
    ticketId: number,
    setTicket: (ticket: ITicket) => void,
    setStatus: (status: Status) => void
) {
    helpdeskService.getTicketType(ticketId)
        .then(type => {
            if (type === "hr") {
                hrHelpdeskService.getTicket(ticketId)
                    .then(ticket => {
                        setTicket(ticket);
                        setStatus(Status.LOAD_SUCCESS);
                    })
                    .catch(() => setStatus(Status.LOAD_ERROR));
            } else if (type === "it") {
                itHelpdeskService.getTicket(ticketId)
                    .then(ticket => {
                        setTicket(ticket);
                        setStatus(Status.LOAD_SUCCESS);
                    })
                    .catch(() => setStatus(Status.LOAD_ERROR));
            } else {
                setStatus(Status.LOAD_ERROR);
            }
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}