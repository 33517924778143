import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LinkTile from "./LinkTile";
import { useEffect, useState } from "react";
import Status from "../../enums/Status";
import accountService from "../../services/accountService";

export default function ActionTiles() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [shouldShowApprovals, setShouldShowApprovals] = useState<boolean>(false);

    useEffect(() => {
        fetchTileVisibility(setShouldShowApprovals)
            .then(() => setStatus(Status.LOAD_SUCCESS))
            .catch(() => setStatus(Status.LOAD_ERROR));
    }, []);

    if ([Status.LOADING, Status.LOAD_ERROR].includes(status) ||
        !shouldShowApprovals // TODO: Check other tiles' visibility
    ) {
        return <></>;
    }

    return (
        <Row>
            <Col>
                <Row>
                    <h4 className="mb-1">{t("home.sections.actions.title")}</h4>
                    <p className="lead">
                        {t("home.sections.actions.subtitle")}
                    </p>
                </Row>
                <Row>
                    {
                        shouldShowApprovals &&
                        <LinkTile   titleKey="approval.titles.singular"
                                    descriptionKey="approval.description"
                                    link="/requests"
                                    image="/img/tiles/approval_tile.jpg"
                                    />
                    }
                </Row>
            </Col>
        </Row>
    );
}

async function fetchTileVisibility(
    setShouldShowApprovals: (value: boolean) => void
) {
    try {
        const result = process.env.REACT_APP_FEATURE_FLAG_APPROVALS_SUPPORT === "true" && await accountService.isApprover();
        setShouldShowApprovals(result);

        // TODO: Fetch other tiles
    } catch (e) {
        console.error(e);
    }
}